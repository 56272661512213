import { useTranslation } from 'react-i18next';
import { LiveFrame } from '#app/components/LiveFrame.tsx';
import { type Gradient } from '#app/lib/firebase/models/gradient.ts';
import { type Settings } from '#app/lib/firebase/repository/settings.ts';
import { BackgroundGradient } from './BackgroundGradient.tsx';

export type ComingSoonProps = {
  gradients: Array<Gradient>;
  settings: Settings;
};

export const ComingSoon = ({ gradients, settings }: ComingSoonProps) => {
  let { t } = useTranslation();

  return (
    <section className="coming-soon">
      <BackgroundGradient gradients={gradients} duration={5} />

      <h1>Women Of the World</h1>
      <p>The wow.earth platform will be launched on November 20, 2023. From this day on you will be able to access and participate.</p>

      {settings && settings.showYouTubeLive && <LiveFrame className="with-coming-soon" url={settings.youTubeLiveUrl} title={t('wow_live-title')} />}

      <footer className="coming-soon-footer">
        <div className="coming-soon-footer-bottom">
          <div className="coming-soon-footer-bottom-inner">
            <p>
              {/* eslint-disable-next-line remix-react-routes/use-link-for-routes */}
              &copy; Pierre Maraval {new Date().getFullYear()} · <a href="mailto:info@wow.earth">info@wow.earth</a>
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};
