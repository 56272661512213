import { useLoaderData } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { AboutAndFooter } from '#app/components/AboutAndFooter.tsx';
import { ComingSoon } from '#app/components/ComingSoon.tsx';
import { Gallery } from '#app/components/Gallery/Gallery.tsx';
import { LiveFrame } from '#app/components/LiveFrame.tsx';
import { Seo } from '#app/components/Seo.tsx';
import { type SpaceLoaderData, spaceLoaderServer } from './space.loader.server.ts';

export const loader = spaceLoaderServer;

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: 'common'
};

export default function RootIndex() {
  let { t } = useTranslation();
  const { settings, url, locale, seo, gradients } = useLoaderData<SpaceLoaderData>();

  return (
    <>
      {seo && <Seo url={url} locale={locale} siteName={seo.siteName} title={seo.title} description={seo.description} imageUrl={'/wow-og-image.jpg'} />}

      {/*Coming Soon*/}
      {settings && settings.comingSoonDomains && settings.comingSoonDomains.includes(new URL(url).host) && <ComingSoon settings={settings} gradients={gradients} />}

      {!settings ||
        (settings && !settings.comingSoonDomains.includes(new URL(url).host) && (
          <>
            {settings && settings.showYouTubeLive && <LiveFrame url={settings.youTubeLiveUrl} title={t('wow_live-title')} />}

            <Gallery />
            <AboutAndFooter />
          </>
        ))}
    </>
  );
}
