import { InView } from 'react-intersection-observer';

export type LiveFrameProps = {
  url: string;
  title: string;
  className?: string;
};

export const LiveFrame = ({ url, title, className }: LiveFrameProps) => {
  return (
    <InView>
      {({ inView, ref, entry }) => (
        <div ref={ref} className={`wow-live-iframe-wrapper ${className ?? ''}`}>
          {inView && (
            <iframe
              loading="lazy"
              src={url}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      )}
    </InView>
  );
};
