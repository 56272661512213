import { motion, useInView, useAnimation, type Variant, easeInOut, type Variants } from 'framer-motion';
import { useEffect, useRef } from 'react';

type AnimatedTitleProps = {
  text: string | string[];
  el?: keyof JSX.IntrinsicElements;
  className?: string;
  once?: boolean;
  repeatDelay?: number;
  animation?: {
    hidden?: Variant;
    visible?: Variant;
  };
};

const defaultAnimations: Variants = {
  hidden: {
    opacity: 0,
    y: `50px`
  },
  visible: {
    opacity: 1,
    y: `0px`,
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9]
      // ease: easeInOut
    }
  }
};

export const AnimatedTitle = ({ text, el: Wrapper = 'p', className, once, repeatDelay, animation = defaultAnimations }: AnimatedTitleProps) => {
  const controls = useAnimation();

  const textArray = Array.isArray(text) ? text : [text];
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once });

  useEffect(() => {
    let timeout: any;

    const show = () => {
      controls.start('visible');

      if (repeatDelay) {
        timeout = setTimeout(async () => {
          await controls.start('hidden');
          controls.start('visible');
        }, repeatDelay);
      }
    };

    if (isInView) {
      show();
    } else {
      controls.start('hidden');
    }

    return () => clearTimeout(timeout);
  }, [isInView, controls, repeatDelay]);

  return (
    <Wrapper className={className}>
      <span className="visuallyhidden">{textArray.join(' ')}</span>
      <motion.span
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { transition: { staggerChildren: 0.05, delayChildren: 0.8 } },
          hidden: {}
        }}
        aria-hidden
      >
        {textArray.map((line, lineIndex) => (
          <span className="block" key={`${line}-${lineIndex}`}>
            {line.split(' ').map((word, wordIndex) => (
              <span className="inline-block" key={`${word}-${wordIndex}`}>
                {word.split('').map((char, charIndex) => (
                  <motion.span key={`${char}-${charIndex}`} className="inline-block" variants={animation}>
                    {char}
                  </motion.span>
                  // <span key={`${char}-${charIndex}`} className="inline-block">
                  //   {char}
                  // </span>
                ))}
                <span className="inline-block">&nbsp;</span>
              </span>
            ))}
          </span>
        ))}
      </motion.span>
    </Wrapper>
  );
};
