import { motion, useInView, useAnimation, type Variant, type Variants } from 'framer-motion';
import { useEffect, useRef } from 'react';

type AnimatedPictureProps = {
  pictureSrc: string;
  className?: string;
  once?: boolean;
  repeatDelay?: number;
  animation?: {
    hidden?: Variant;
    visible?: Variant;
  };
};

const defaultAnimations: Variants = {
  hidden: {
    opacity: 0,
    y: 160
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      ease: [0.2, 0.65, 0.3, 0.9]
    }
  }
};

export const AnimatedPicture = ({ pictureSrc, className, once, repeatDelay, animation = defaultAnimations }: AnimatedPictureProps) => {
  const controls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once });

  useEffect(() => {
    let timeout: any;

    const show = () => {
      controls.start('visible');

      if (repeatDelay) {
        timeout = setTimeout(async () => {
          await controls.start('hidden');
          controls.start('visible');
        }, repeatDelay);
      }
    };

    if (isInView) {
      show();
      // console.log('picture show!');
    } else {
      // controls.start('hidden');
    }

    return () => clearTimeout(timeout);
  }, [isInView, controls, repeatDelay]);

  return (
    <div ref={ref} className={className}>
      <motion.img src={pictureSrc} alt="" initial="hidden" animate={controls} variants={animation} />
    </div>
  );
};
