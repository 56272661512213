import { Link } from '@remix-run/react';
import { Fragment, useRef } from 'react';
import { type AboutContent } from '#app/lib/firebase/repository/about.ts';

export type ContentProps = {
  content: Array<AboutContent> | undefined;
};

export const Content = ({ content }: ContentProps) => {
  const containerRef = useRef(null);

  if (!content) {
    return null;
  }

  const getLinkHrefForButton = (value: string) => {
    const json = JSON.parse(value);
    // @ts-ignore
    return json.href;
  };

  const getLinkLabelForButton = (value: string) => {
    const json = JSON.parse(value);
    // @ts-ignore
    return json.label;
  };

  const onShare = () => {
    if (typeof document !== 'undefined') {
      if (navigator && navigator.share) {
        navigator
          .share({
            // @ts-ignore
            title: 'WOW - Women Of the World',
            text: 'Join WOW',
            url: window.location.href
          })
          .then(() => console.log('Successful share'))
          .catch(error => console.log('Error sharing:', error));
      } else if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href).then(() => console.log('Url copied into clipboard!'));
      }
    }
  };

  return (
    <div ref={containerRef} className="editorial-content">
      {content.map((c, index) => (
        <Fragment key={`content-${index}`}>
          {c.type === 'text' && <div dangerouslySetInnerHTML={{ __html: c.value }} />}
          {c.type === 'share' && (
            <div className="about-link-container">
              <button type="button" className="button button-link about-link" onClick={() => onShare()}>
                {c.value}
              </button>
            </div>
          )}
          {c.type === 'link' && (
            <div className="about-link-container">
              <Link to={getLinkHrefForButton(c.value)} className="button button-link about-link">
                {getLinkLabelForButton(c.value)}
              </Link>
            </div>
          )}
          {c.type === 'youtube' && (
            <div className="iframe-wrapper">
              <iframe
                loading="lazy"
                width="560"
                height="315"
                src={c.value}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
